<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          sm="auto"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Ventas</h3>
        </b-col>

        <!-- Search -->
        <b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block ml-1 mr-1 search-input"
              placeholder="Buscar por folio..."
            />
            <div
              class="d-flex align-items-center"
              style="width: 220px;"
            >
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range'}"
                class="form-control  bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
                @on-change="updateRanges()"
              />
            </div>
            <b-button
              v-if="userData.role_name === 'admin'"
              variant="outline-warning"
              @click="downloadFile()"
            >
              Obtener archivo de conciliación
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="orders"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(folio)="data">
        <b-link
          :to="{ name: 'order-view', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.item.auto_inc_folio }}
        </b-link>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(invoiceStatus)="data">
        <b-avatar
          :id="`invoice-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).variant}`"
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ data.item.invoiceStatus }}
          </p>
          <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: store clerk -->
      <template #cell(store_clerk)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.store_clerk.logo"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.store_clerk.name }}
          </span>
          <small class="text-muted">{{ data.item.store_clerk.email }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(created_at)="data">
        <span class="text-nowrap mr-2">
          {{ data.value | dateNtime }}
        </span>
      </template>

      <!-- Column: Total -->
      <template #cell(total)="data">
        <div>
          <feather-icon
            :icon="data.item.order_type === 'emida' ? 'TrendingUpIcon':'TrendingDownIcon'"
            :class="data.item.order_type === 'emida' ? 'text-success':'text-danger'"
            class="mr-1"
          />
          ${{ data.value | money }}
        </div>
      </template>

      <!-- Column: Emida order type -->
      <template #cell(emida_type)="data">
        <div>
          {{ data.item.emida_response.flow_type === 'b' ? 'Pago de servicio' : 'Recarga' }}
        </div>
      </template>

      <!-- Column: Payment type -->
      <template #cell(payment_type)="data">
        <template>
          <b-badge
            pill
            :variant="data.value === 'cash' ? 'light-success':'light-info'"
          >
            {{ data.value | paymentType }}
          </b-badge>
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(tools)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="SendIcon"
            class="cursor-pointer"
            size="16"
          />
          <b-tooltip
            title="Send Invoice"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-send-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'order-view', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <label># de resultados</label>
          <v-select
            v-model="entriesPerPage"
            :dir="'ltr'"
            :options="perPage"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.total_objects"
            :per-page="pagination.per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="(value)=>{handlePagination({ page: value, per_page: pagination.per_page })}"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <b-spinner
                v-if="loading"
                small
                label="Loading"
              />
              <feather-icon
                v-else
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  // BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  // BDropdown,
  // BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BSpinner,
    flatPickr,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      loading: false,
      entriesPerPage: '10',
      searchQuery: '',
      rangePicker: ['2021-09-01', '2021-09-20'],
      startDate: null,
      endDate: null,
      tableColumns: [
        'folio',
        {
          key: 'store_clerk',
          label: 'Cajero',
        },
        {
          key: 'created_at',
          label: 'Fecha',
        },
        {
          key: 'emida_response.pin',
          label: 'Referencia',
        },
        'total',
        {
          key: 'emida_type',
          label: 'Tipo de orden',
        },
        {
          key: 'payment_type',
          label: 'Tipo de pago',
        },
        {
          key: 'tools',
          label: 'Herramientas',
        },
      ],
      perPage: ['10', '20', '50', '100'],
    }
  },
  computed: {
    ...mapGetters('orders', [
      'pagination',
      'orders',
    ]),
  },
  watch: {
    entriesPerPage() {
      this.loading = true
      this.fetchOrders({
        meta: {
          pagination: {
            page: this.pagination.page,
            per_page: this.entriesPerPage,
          },
        },
      }).then(() => {
        this.loading = false
      })
    },
  },
  mounted() {
    const establishmentId = this.userData?.role_name === 'establishment_admin' ? this.userData.scoped_roles[0].role_resource_id : null
    this.loading = true
    this.fetchOrders({
      by_order_type: 'emida',
      by_establishment: establishmentId,
    })
      .then(() => {
        this.loading = false
      })
  },
  methods: {
    ...mapActions('orders', [
      'fetchOrders',
    ]),
    ...mapActions('emidaComissions', [
      'getEmidaConsolidationFile',
    ]),
    updateRanges() {
      this.startDate = Array.isArray(this.rangePicker) ? this.rangePicker[0] : this.rangePicker.substring(0, 10)
      this.endDate = Array.isArray(this.rangePicker) ? this.rangePicker[1] : this.rangePicker.substring(14)
      this.fetchOrders({
        by_order_type: 'emida',
        by_date: {
          start_date: this.startDate,
          end_date: this.endDate,
        },
      })
    },
    downloadFile() {
      this.getEmidaConsolidationFile({ start_date: this.startDate, end_date: this.endDate })
        .then(response => {
          const csvContent = `data:text/csv;charset=utf-8,${response.split(',')}`
          const encodedUri = encodeURI(csvContent)
          window.open(encodedUri)
        })
    },
    handlePagination({ page, per_page }) {
      const establishmentId = this.userData?.role_name === 'establishment_admin' ? this.userData.scoped_roles[0].role_resource_id : null
      this.loading = true
      this.fetchOrders({
        by_establishment: establishmentId,
        by_order_type: 'emida',
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      })
        .then(() => {
          this.loading = false
        })
    },
    formatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    formatFirstDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = '1'
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input{
  max-width: 200px;
}
</style>
